.vis-item {
  border-width: 2px;
  border-color: transparent;
}
.vis-item.vis-range.vis-selected {
  border-color: black;
}

.vis-item.no-end {
  background-image: linear-gradient(to left, white, transparent 20px);
  border-right: none;
}

.vis-item.vis-range.vis-selected.no-end {
  border-width: 2px 0 2px 2px;
  border-image: linear-gradient(to left, white, black 20px) 1;
  box-sizing: content-box;
}
