@font-face {
  font-family: 'Nunito Sans';
  font-weight: 300;
  src: url('NunitoSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 400;
  src: url('NunitoSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 500;
  src: url('NunitoSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 600;
  src: url('NunitoSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 700;
  src: url('NunitoSans-Bold.ttf') format('truetype');
}
