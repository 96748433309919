.mapboxgl-ctrl-attrib {
  right: 2.75px;
}
.mapboxgl-popup {
  bottom: 1px;
  margin-top: 5px;
}
.mapboxgl-popup .mapboxgl-popup-tip {
  display: none;
}
.mapboxgl-popup .mapboxgl-popup-content {
  max-width: 300px;
  color: rgba(0, 0, 0, 0.9);
  background-color: rgb(240, 240, 240);
  padding: 4px 8px;
  font-size: 11px;
  box-shadow: 1px 1px 2px #222;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
}
