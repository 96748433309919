@import "./fonts/nunito.css";

body {
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-family: 'Nunito Sans', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  /* overflow: hidden; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#root {
  display: block;
  height: 100vh;
  width: 100vw;
}

/* Need to define markers in here, there was an issue
 * with re-creating the marker if it was defined in js
 * and rendered using react-dom */
.fathom-marker {
  background-image: url("./img/fathom-lab-marker-40.png");
  background-position: center;
  background-size: cover;
  width: 35px;
  height: 40px;
  text-align: center;
  cursor: pointer;
  z-index: 1000;
}
.fathom-marker-edit {
  background-image: url("./img/fathom-edit-marker.png");
  background-size: cover;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
